<template>
  <div class="gridMainLayout">
    <div v-if="dataFetched"
      :class="[
        localDisplayLang === 'ara'
          ? 'mainContainerContinueWatchAra'
          : 'mainContainerContinueWatch',
      ]"
      :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"
      class="mt-5 gridContainer"
    >
      <div
        class="continueWatchCardContainer gridCardWrapper" 
        v-for="(item, index) in continueData"
        :key="index"
        @click="playContent(item, index)"
      >
        <div class="continueWatchCard gridCardLayout">
          <div class="gridPosterLayout">
            <ImageTemplate :content="item" :borderRadius="'4px'"></ImageTemplate>
            <img
              src="@/assets/icons/remove-watchlist.svg"
              class="removeFromWatch"
              @click.stop="removeFromWatchlist(item)"
              alt
            />          
            <div class="play-img">
              <img src="@/assets/icons/Ic_Play1.svg" alt="" />            
            </div>
            <div
              v-if="dataFetched"
              class="grayLine"
              :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"
            ></div>
            <div
              class="progressPercent"
              :style="{ width: calculatePercentage(item) + '%' }"
              :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"
            ></div>
          </div>
          <div class="gridContentLayout">
            <div class="gridContentTitle">
              <p class="gridTitle" :title="item.title">{{ item.title }}</p>
            </div>
            <div class="gridContentDetails">
              <div class="main-info">
                <div class="ua-rating" v-if="item.advisory">
                  <p>{{ item.advisory }}</p>
                </div>
                <div class="publish-year" v-if="item.productionyear">
                  <p>{{ item.productionyear }}</p>
                </div>
                <div class="duration" v-if="item.duration">
                  <p>{{ calcTimeFormat(item.duration) }}</p>
                </div>
                <div class="pgrating" v-if="item.pgrating">
                  <p>{{ item.pgrating }}</p>
                </div>
              </div>
              <div class="main-genre">
                <div class="sub-genre1" v-if="item.category">
                  <p>{{ $t(item.category) }}</p>
                </div>
                <div
                  class="sub-genre2"
                  v-if="item.genre"
                  :class="{ 'with-bullet': item.category }"
                >
                  <p>{{ $t(item.genre) }}</p>
                </div>
                <div
                  class="sub-genre3"
                  v-if="item.contentlanguage && item.contentlanguage.length > 0"
                  :class="{ 'with-bullet': item.category || item.genre }"
                >
                  <p>{{ $t(item.contentlanguage) }}</p>
                </div>
              </div>
            </div>
            <p class="gridDescription" v-if="item.shortdescription || item.longdescription">
              {{ getDescription(item, 150) }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!dataFetched">
      <GridLayoutPlaceholder></GridLayoutPlaceholder>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import firebase from "firebase/app";
import "firebase/database";
import Utility from "@/mixins/Utility.js";
import FirebaseActions from "@/mixins/FirebaseActions";
import PlaybackUtilities from "@/mixins/playbackUtilities.js";
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    playerInstance: {
      type: Object,
    },
  },
  data() {
    return {
      movieObjs: [],
      episodeObjs: [],
      continueWatchFirebaseData: null,
      newEpisodeObj: null,
      segEpisodeArr: null,
      continueWatchContent: [],
      continueWatchDataTemp: null,
      localDisplayLang: null,
      page: 1,
      pageMovie: 1,
      seriesPage: 1,
      moviesPage: 1,
      isMpegRequired: false,
      finalData: [],
      pageSize: 15,
      continueData: null,
      finalMovieData: [],
      dataFetched: false,
      episodeList: [],
      totalEpisodeCount: 0,
      isDirectPlay: true,
      availabilities: [],
      filteredAvailabilities: [],
      gotPosterItem: null,
      placeholder_port: require('@/assets/placeholder-port.svg'),
      placeholder_lands: require('@/assets/placeholder-lands.svg'),
    };
  },

  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    this.localDisplayLang = this.localDisplayLang == 'ml' ? 'mal' : this.localDisplayLang;

    
    // this.continueWatchFirebaseData = this.$route.params.continueWatchData;
    if (this.availabilityList !== undefined) {
      this.availabilities = this.availabilityList;
    }

    //assigning subscription list response.
    if (this.subscriptionList !== undefined) {
      this.subscriptions = this.subscriptionList;
    }
    this.scrollTop();
    this.checkEpisodeContinueWatch();
    // this.filterContents();
  },
  components: {
    ImageTemplate: () => import(/* webpackChunkName: "imageTemplate" */ "@/components/Templates/Image.vue"),
    GridLayoutPlaceholder: () => import(/* webpackChunkName: "gridplaceholder" */ "@/components/placeholders/GridLayoutPlaceholder.vue"),
  },
  watch: {
    subscriptionList(val) {
      if (val) {
        this.subscriptions = val;
        // this.getAvailability(this.content);
      }
    },
    availabilityList(val) {
      if (val) {
        this.availabilities = val;
        // this.getAvailability(this.content);
      }
    },
  },
  methods: {
    ...mapActions(["listContent"]),
    checkEpisodeContinueWatch() {
      this.continueWatchContent = [];
      this.continueWatchData = [];
      this.detailPlaylist = [];
      this.finalPlaylist = [];
      this.completeEpisodeListing = [];
      this.continueData = [];
      let path = "",
        response = null,
        episodeObjs = null,
        newEpisodeObj = null,
        segEpisodeArr = null,
        movieObjs = null;

      if (this.subscriberid || this.profileid) {
        path = `/subscriber/${this.subscriberid}/${this.profileid}/content/`;

        firebase
          .database()
          .ref(path)
          .once("value")
          .then((snapshot) => {
            response = this.collectionsToArray(snapshot.val());
            //this.totalFirebaseResponse = response

            // Filter the movies for continue watch

            movieObjs = response.filter((element) => {
              return (
                element.category === "MOVIE" &&
                element.status === "INPROGRESS" &&
                element.watchedduration
              );
            });

            

            // Filter only the series with episodes key
            episodeObjs = response.filter((element) => {
              return element.episodes;
            });

            // Convert the collection to an array
            newEpisodeObj = [...this.collectionsToArray(episodeObjs)];

            // Create an array of only the episodes of the particular series
            newEpisodeObj.forEach((element) => {
              segEpisodeArr = this.collectionsToArray(element.episodes);
              //assign series title to each episode
              segEpisodeArr.forEach((el) => {
                el.title = element.title;
              });

              // Sort episodes based on timestamp
              segEpisodeArr = segEpisodeArr.sort((a, b) => {
                return b.updatedat - a.updatedat;
              });

              // Pick the first index after sorting as it will be the latest episode watched
              this.continueWatchContent.push(segEpisodeArr[0]);
            });

            this.continueWatchDataTemp = [
              ...this.continueWatchContent,
              ...movieObjs,
            ];

            

            this.continueWatchDataTemp = this.continueWatchDataTemp.sort(
              (a, b) => {
                return b.updatedat - a.updatedat;
              }
            );
            this.checkLanguage();
          });
      }
    },
    scrollTop() {
      window.scrollTo(0, 0);
    },
    calculatePercentage(item) {
      // console.log('PERCENTAGE IS ',item)
      return (item.watchedduration / item.duration) * 100;
    },
    filterContents() {
      // console.log(
      //   "continue watch firebase data from store",
      //   this.getContinueWatchData
      // );
      // this.continueWatchDataTemp = this.getContinueWatchData;
      //this.checkLanguage();
    },
    removeFromWatchlist(item) {
      
      eventBus.$emit("removeFromContinueWatch", item);
      setTimeout(() => {
        this.checkEpisodeContinueWatch();
      }, 2000);
      this.dataFetched = false;
    },
    checkLanguage() {
      

      const seriesObjIds = [];
      const moviesObjIds = [];

      
      this.continueWatchDataTemp.forEach((element) => {
        

        if (element.category == "TVSHOW") {
          seriesObjIds.push(`"${element.objectid}"`);
        } else if (element.category == "MOVIE") {
          moviesObjIds.push(`"${element.objectid}"`);
        }
      });
      this.callPromises(seriesObjIds, moviesObjIds);
    },
    playContent(content) {
      if (this.isDirectPlay && this.subscriberid) {
        this.showPlayer();
        if(content && content.playbacktype == "EXTERNALURLBASEDPLAYBACK") {
        let episodeIndex = 0;
        let episodeList = [content];
        let totalCount = episodeList.length;
        this.actBuyDRMToPlayer(episodeIndex, episodeList, totalCount, true);
        return
      } else {
        if (content.category == "MOVIE") {
          let episodeIndex = 0;
          this.episodeList = [content];
          this.totalEpisodeCount = 1;

          this.loadChosenIndexContent(
            episodeIndex,
            this.episodeList,
            this.totalEpisodeCount,
            true
          );
        } else {
          let returnCode = this.fetchEpisodeRecursively(
            this.fetchEpisodes,
            content,
            this.checkEpisodeInList,
            false
          );

          returnCode
            .then(() => {
              let episodeIndex = this.getEpisodeIndexInList(
                content.objectid,
                this.episodeList
              );

              this.loadChosenIndexContent(
                episodeIndex,
                this.episodeList,
                this.totalEpisodeCount,
                true
              );
            })
            .catch((error) => {
              console.log("Error encountered : ", error);
            });
        }
      }
      } else if (!this.subscriberid) {
        //open lookup popup.
        let payload = {
          state: true,
          formType: "lookup",
        };
        eventBus.$emit("authPopup", payload);
      }
    },
    showPlayer() {
      // this.playerInstance.blowUpPlayer();
      this.playerInstance.registerEvent(
        "currentcontentended",
        this.loadNextContentData
      );
      this.playerInstance.registerEvent(
        "loadchosencontent",
        this.loadChosenContentData
      );
      this.playerInstance.registerEvent(
        "loadmorecontent",
        this.loadMoreContentData
      );

      this.playbackInitiated = true;
      eventBus.$emit("player-visibility", true);
    },
    callPromises(seriesObjIds, moviesObjIds) {
      let seriesPromise;
      let moviesPromise;
      //SERIES PROMISE
      if (seriesObjIds && seriesObjIds.length > 0) {
        const payload = {
          contentlist: "[" + seriesObjIds + "]",
          displaylanguage: this.localDisplayLang,
          page: this.seriesPage,
          pageSize: this.pageSize,
        };
        seriesPromise = new Promise((resolve, reject) => {
          this.fetchSeriesDataRecursively(payload).then((data) => {
            this.continueWatchDataTemp.forEach((content, cIndex) => {
              data.forEach((data, dIndex) => {
                if (data.objectid === content.objectid) {
                  this.continueWatchDataTemp[cIndex] = {
                    ...data,
                    status : content.status,
                    updatedat: content.updatedat,
                    watchedduration: content.watchedduration
                  };
                }
              });
            });

            setTimeout(()=>{
              resolve(this.continueWatchDataTemp);
            },2000)
          });
        });
      }

      //MOVIE PROMISE
      if (moviesObjIds && moviesObjIds.length > 0) {
        const payload = {
          contentlist: "[" + moviesObjIds + "]",
          displaylanguage: this.localDisplayLang,
          objecttype: "MOVIE",
          page: this.moviesPage,
          pageSize: this.pageSize,
        };
        moviesPromise = new Promise((resolve, reject) => {
          this.fetchMoviesDataRecursively(payload).then((data) => {
            this.continueWatchDataTemp.forEach((content, cIndex) => {
              data.forEach((data, dIndex) => {
                if (data.objectid === content.objectid) {
                  this.continueWatchDataTemp[cIndex] = {
                    ...data,
                    status : content.status,
                    updatedat: content.updatedat,
                    watchedduration: content.watchedduration
                  };
                }
              });
            });

           setTimeout(()=>{
              resolve(this.continueWatchDataTemp);
           },2000)
          });
        });
      }

      //RESOLVE BOTH PROMISES
      Promise.all([seriesPromise, moviesPromise]).then((finalData) => {
        this.continueWatchData = finalData[1];
        if (this.continueWatchData && this.continueWatchData.length > 0) { 
         this.continueData = this.continueWatchData.sort((a, b) => {
            return b.updatedat - a.updatedat;
          });
        }
        this.continueData = this.continueData.filter((item) => {
          return item.status && item.watchedduration;
        });
        this.dataFetched = true;
      });
    },
    fetchSeriesDataRecursively(payload) {
      return new Promise((resolve, reject) => {
        this.listContent(payload).then((data) => {
          this.finalData = [...this.finalData, ...data.data.data];
          if (this.finalData.length < data.data.totalcount) {
            let newPayload = {
              contentlist: payload.contentlist,
              displaylanguage: payload.displaylanguage,
              page: payload.page + 1,
              pagesize: this.pageSize,
            };

            this.fetchSeriesDataRecursively(newPayload);
          }
          setTimeout(() => {
            resolve(this.finalData);
          }, 2000);
        });
      });
    },
    fetchMoviesDataRecursively(payload) {
      return new Promise((resolve, reject) => {
        this.listContent(payload).then((data) => {
          this.finalMovieData = [...this.finalMovieData, ...data.data.data];
          if (this.finalMovieData.length < data.data.totalcount) {
            let newPayload = {
              contentlist: payload.contentlist,
              displaylanguage: payload.displaylanguage,
              page: payload.page + 1,
              pagesize: this.pageSize,
            };

            this.fetchMoviesDataRecursively(newPayload);
          }
          setTimeout(() => {
            resolve(this.finalMovieData);
          }, 2000);
          //resolve(this.finalMovieData);
        });
      });
    },
    getPoster(content) {
      if (content.poster && content.poster.length) {
        let index = content.poster.findIndex((element) => {
            return element.postertype === "LANDSCAPE";
        });
        if (index > -1) {
            content.poster[index].filelist.filter((el) => {
              if (el.quality === "THUMBNAIL") {
                this.gotPosterItem = el.filename;
              } else {
                if (el.quality === "LOW") {
                  this.gotPosterItem = el.filename;
                } else if (el.quality === "SD") {
                  this.gotPosterItem = el.filename;
                } else if (el.quality === "HD") {
                  this.gotPosterItem = el.filename;
                }
              }
            });
        } else {
          this.gotPosterItem = content.poster[0].filelist && content.poster[0].filelist[0] ? content.poster[0].filelist[0].filename : ''
        }
      } else {
        if (content.poster && content.poster.landscape) {
          this.gotPosterItem = content.poster.landscape;
        } else {
          this.gotPosterItem = this.placeholder_lands;
        }
        
      }      
      return this.gotPosterItem;
    },
  },
  mounted() {
  },
  computed: {
    ...mapGetters([
      "subscriberid",
      "availabilityList",
      "subscriptionList",
      "purchaseList",
      "profileid",
      "getRtl",
      "getContinueWatchData",
    ]),
  },
  mixins: [Utility, PlaybackUtilities],
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
@import "@/components/Templates/Grid.scss";

.gridMainLayout {
  padding: 2rem;
}

.play-img {
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    height: 36px !important;
    width: 36px !important;
  }
}

.progressPercent {
  position: absolute;
  left: 0%;
  bottom: 1%;
  height: 3%;
  background:  $primary-btn-color;
  width: 90%;
  border-radius: 8px;
  margin: 0 0.2rem;
  z-index: 777;
}
</style>
